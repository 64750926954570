import { SimpleText } from 'components/sanaText';
import { DangerAlert } from 'components/primitives/alerts';
import { Placeholder } from '../placeholders';
import { memo } from 'react';

const BlockedForAnonymousMessage = () => {
    const placeholder = <Placeholder className="placeholder" />;

    return (
        <DangerAlert>
            <SimpleText textKey="BlockedForAnonymous" placeholder={placeholder} />
        </DangerAlert>
    );
};

export default memo(BlockedForAnonymousMessage);
